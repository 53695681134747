<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="姓名">
				  <c-input name="user_realname"></c-input>
				</m-search-item>
				<m-search-item label="手机号">
				  <c-input name="phone"></c-input>
				</m-search-item>
				<m-search-item label="班组">
				   <c-select name="team_id" :options="teamOptions"></c-select>
				</m-search-item>
			</m-search>

			<m-operate>
				<c-button color="sys" @click="createUser">新增员工</c-button>
			</m-operate>

			<c-table ref="table" height="grow">
				<c-table-column
					label="员工姓名"
					width="120"
					name="user_realname"
				></c-table-column>

				<c-table-column
					label="手机号"
					width="150"
					name="phone"
				></c-table-column>
				
				<c-table-column
					  label="班组"
					  width="100"
					  name="team_name"
				>
					<template v-slot="{data}">
						<span v-if="data.team_name">{{data.team_name}}</span>
						<span v-else>--</span>
					</template>
				</c-table-column>

				<c-table-column
					label="创建时间"
					width="140"
					name="create_date"
				></c-table-column>

				<c-table-column
					type="button"
					label="操作"
					width="120"
				>
					<template v-slot="{data}">
						<c-table-button v-if="auth('b_user_edit')" @click="editUser(data)">编辑</c-table-button>
						<c-table-button v-if="auth('b_user_edit')" @click="deleteUser(data)">删除</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>

		<c-dialog ref="userEdit" :title="editState ? '编辑账号' : '新建账号'" width="600" @resolve="submitUser">
			<c-form ref="userForm" unit-width="100%">
				<c-form-item label="员工姓名" required>
					<c-input name="user_realname" required maxlength="15"></c-input>
				</c-form-item>
				<c-form-item label="手机号码">
					<c-input name="phone" required maxlength="11"></c-input>
				</c-form-item>
				<c-form-item label="班组">
					<c-select name="team_id" :options="teamOptions"></c-select>
				</c-form-item>
				<c-input type="hidden" name="id"></c-input>
			</c-form>
		</c-dialog>
	</page>
</template>

<script>
import {cryptoJs} from '@deanwu/vue-component-library'
import {mapState} from 'vuex'

export default {
	name: 'm_employee',
	components: {
		
	},
	data() {
		return {
		  teamOptions:[],
		  editState: false
		}
	},
	computed: {
		
	},
	mounted() {
        this.$refs.search.search();
	    this.loadTeamOptions();
	},
	methods: {
		tabHandle(item) {
			this.$refs.search.search();
		},
		searchHandle(data) {
			data.user_type = 3;
			this.$refs.table.load({
				url: '/sys_user/list',
				data
			});
		},
		//创建账号
		createUser() {
			this.editState = false;
			this.$refs.userForm.clear();
			this.$refs.userForm.set({});
			this.$refs.userEdit.open();
		},
		//编辑账号
		editUser(val) {
			this.editState = true;
			this.$refs.userForm.set(val);
			this.$refs.userEdit.open();
		},
		//删除账号
		deleteUser(val){
			this.$confirm({
				title: '确认',
				message: '确定要删除【'+val.user_name+'】吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/sys_user/delete',
						data: {user_id:val.id},
						loading: true,
						success: data => {
							this.$message({
							  message: '删除成功',
							  type: 'success'
							});
							this.$refs.table.update();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		//提交保存
		submitUser(stop) {
			stop();
			this.$refs.userForm.submit({
				url: this.editState ? '/sys_user/edit' : '/sys_user/add',
				rule: {
					user_realname: '请填写员工姓名'
				},
				dataFormatter: data => {
					data.team_id = data.team_id||0;
					data.user_name = data.user_realname;
					data.user_pwd = cryptoJs.MD5("123456").toString();
					data.user_type = 3;
					data.state = 2;
					return data;
				},
				success: (data, res) => {
					this.$message({
						message: '保存成功',
						type: 'success'
					});
					this.$refs.table.update();
					this.$refs.userEdit.close();
				}
			});
		},
		resetPwd(val) {
			this.$confirm({
				title: '重置登录密码',
				message: '确定要重置【'+val.user_name+'】的密码吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/sys_user/reset_pwd',
						data: {id:val.id},
						loading: true,
						success: data => {
							this.$confirm({
								title: '重置账号密码成功',
								message: data,  // 提示内容,
								buttonName: '知道了',  // 确定按钮文字
								cancelButton: false,  // 是否显示取消按钮
								resolve: () => {},  // 确定按钮回调
								cancel: () => {},  // 取消按钮回调
							});
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		roleConfig(data) {
			this._roleConfig = data;
			this.$refs.roleSelector.open(data.id);
		},
		submitRoleConfig(data) {
			this.request({
				url: '/sys_user/user_role_edit',
				data: {
					user_id: this._roleConfig.id,
					role_ids: Array.from(data, item => {
						return {
						  role_id: item.id
						}
					})
				},
				loading: true,
				success: () => {
					this.$refs.table.update();
				}
			});
		},
		//加载班组数据
		loadTeamOptions(){
			this.request({
				url: '/dic/team/list',
				data: {},
				loading: false,
				success: data => {
					data.forEach(item => {
						var obj = {};
						obj.name = item.team_name;
						obj.value = item.id;
						this.teamOptions.push(obj);
					});
				}
			});
		}
	}
}
</script>